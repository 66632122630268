import React, { useEffect, useState } from 'react';
import Div100vh from 'react-div-100vh';
import {
	Button,
	FormError,
	Input,
	PhoneInput,
	Preloader
} from '@components/common/elements';
import { signIn } from '@lib/api/auth';
import { useLocales, useUrlLang } from '@lib/hooks';
import { AnalyticsEventType, ButtonType, ModalType, SessionStorageParams } from '@types';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { useSelector } from 'react-redux';
import { authSelectors } from '@store/reducers/auth/selectors';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { sendAnalyticsHandler } from '@utils';
import { EMAIL_ENDS } from '@config/global';
import { CloseSvg, GoogleIcon } from '@components/svg';
import { getPhoneNumberByRegion } from '@utils/phoneNumberUtil';
import { userSelectors } from '@store/reducers/user/selectors';
import { getCountries } from 'react-phone-number-input';
import { CountryCode } from 'libphonenumber-js';
import { getImageByURL } from '@utils/getImageByURL';
import { setAuthType, setIsAuthPending } from '@store/reducers/auth/dispathcers';
import s from './style.module.scss';
import cn from 'classnames';
import * as Yup from 'yup';
import 'yup-phone-lite';

import QueryString from 'qs';
import { useGetButtonsLang } from '@lib/hooks/useGetAuthButtonsLang';

type FetchCreateNewUserParams = {
	email: string;
	phone: string;
	password: string;
	rememberMe?: boolean;
};

export enum MethodTypesE {
	email = 'email',
	phone = 'phone',
	google = 'google',
	telegram = 'telegram'
}

export const SignInModal = () => {
	const { localizeText } = useLocales({
		path: 'components/common/modals',
		node: 'AuthModal'
	});

	const navigate = useNavigate();
	const { langUrlPrefix } = useUrlLang();

	const { isAuthPending } = useSelector(authSelectors.authInfo);
	const { modalType, isApp } = useSelector(commonUISelectors.commonUIInfo);
	const { locationData } = useSelector(userSelectors.userInfo);

	const { renderLangSignIn, renderLangSignUp } = useGetButtonsLang();

	const queryParams = {
		isApp: isApp
	};

	const queryString = QueryString.stringify(queryParams);

	const baseGoogleURL = `${process.env.REACT_APP_API_URL}/auth/google?${queryString}`;

	const redirectMethodTypes: Array<{
		text: string;
		type: MethodTypesE;
		url: string;
		icon: JSX.Element;
	}> = [
		{
			text: 'Google',
			type: MethodTypesE?.google,
			url: baseGoogleURL,
			icon: <GoogleIcon />
		}
		// {
		// 	text: 'Telegram',
		// 	type: MethodTypesE?.telegram,
		// 	url: '',
		// 	icon: <TelegramIconAuth />
		// }
	];

	const methodTypes: Array<{ text: string; type: MethodTypesE }> = [
		{
			text: 'E-mail',
			type: MethodTypesE?.email
		},
		{
			text: localizeText('phone'),
			type: MethodTypesE?.phone
		}
	];

	const [methodType, setMethodType] = useState<MethodTypesE>(methodTypes[0]?.type);
	const [countryCode, setCountryCode] = useState(
		locationData?.countryCode || getCountries()[0]
	);

	const { nationalPrefix } = getPhoneNumberByRegion(countryCode);

	const [loginAttempts, setLoginAttempts] = useState<number>(0);
	const [forgotPassBanner, setForgotPassBanner] = useState<boolean>(false);

	const increaseAttemptsHandler = () => setLoginAttempts((a) => a + 1);

	useEffect(() => {
		if (loginAttempts === 3) {
			setForgotPassBanner(true);

			sendAnalyticsHandler(AnalyticsEventType?.signin_banner_show_forgot_password);
		}
	}, [loginAttempts]);

	useEffect(() => {
		if (modalType === ModalType?.signIn) {
			sendAnalyticsHandler(AnalyticsEventType?.signin_open_modal);
		}
	}, [modalType]);

	const closeFogotPasswordBannerHandler = () => {
		setLoginAttempts(0);
		setForgotPassBanner(false);

		sendAnalyticsHandler(AnalyticsEventType?.signin_banner_click_close);
	};

	const clickFogotPasswordBannerHandler = () => {
		setLoginAttempts(0);
		setForgotPassBanner(false);

		setModalType(ModalType?.resetPass);

		sendAnalyticsHandler(AnalyticsEventType?.signin_banner_click_forgot_password);
	};

	const validationSchema = Yup.object().shape({
		email:
			methodType === MethodTypesE?.email
				? Yup.string()
						.email('invalid_email')
						.transform((value, originalValue) =>
							typeof originalValue === 'string'
								? originalValue.replace(/\s/g, '')
								: originalValue
						)
						.required('need_fill_field')
				: Yup.string(),

		phone:
			methodType === MethodTypesE?.phone
				? Yup.string()
						.phone(countryCode as CountryCode, 'invalid_phone')
						.required('need_fill_field')
				: Yup.string(),

		password: Yup.string()
			.min(6, 'invalid_password_length')
			.max(40, 'invalid_password_length')
			.required('need_fill_field')
	});

	const handleSubmit = (values: FetchCreateNewUserParams) => {
		const countrycode = `+${nationalPrefix}`;

		const newValues = {
			email:
				methodType === MethodTypesE?.email
					? values?.email?.replace(/\s/g, '')
					: undefined,
			phone:
				methodType === MethodTypesE?.phone
					? `${countrycode}${values?.phone}`.replace(/[\s-]/g, '')
					: undefined,

			password: values.password?.length > 0 ? values.password : undefined,
			isApp: isApp
		};

		const successFunc = () => {
			setIsAuthPending(false);
			setAuthType(null);
			setModalType(null);

			const returnGame = sessionStorage.getItem(SessionStorageParams?.returnGame);

			if (values?.rememberMe) {
				localStorage?.setItem(
					'user_creds',
					JSON.stringify({
						email: values?.email,
						password: values?.password,
						phone: values?.phone
					})
				);
			}

			if (returnGame) {
				navigate && navigate(langUrlPrefix + returnGame);
				sessionStorage.removeItem(SessionStorageParams?.returnGame);
			}
		};

		signIn(newValues, formik.setFieldError, successFunc, increaseAttemptsHandler);
	};

	const formik = useFormik({
		initialValues: {
			email: '',
			phone: '',
			password: '',
			rememberMe: true
		},
		validationSchema: validationSchema,
		onSubmit: handleSubmit
	});

	useEffect(() => {
		if (modalType === ModalType?.signIn) {
			formik?.resetForm();

			const creds = localStorage?.getItem('user_creds');

			if (creds) {
				const credsJSON = JSON.parse(creds);

				if (credsJSON?.email) {
					formik?.setFieldValue('email', credsJSON?.email);
				}

				if (credsJSON?.password) {
					formik?.setFieldValue('password', credsJSON?.password);
				}

				if (credsJSON?.phone) {
					formik?.setFieldValue('phone', credsJSON?.phone);
				}
			}
		}
	}, [modalType]);

	const closeModalHandler = () => {
		const returnGame = sessionStorage.getItem(SessionStorageParams?.returnGame);

		if (returnGame) {
			navigate && navigate(langUrlPrefix + returnGame);
			sessionStorage.removeItem(SessionStorageParams?.returnGame);

			return;
		}

		setModalType(null);
		navigate(location?.pathname + location?.search);
	};

	return (
		<Div100vh
			onClick={({ target, currentTarget }) => {
				if (target === currentTarget) closeModalHandler();
			}}
			className={s.wrapper}
		>
			<form
				onSubmit={formik.handleSubmit}
				className={s.inner}
			>
				<div className={s.header}>
					<span>{renderLangSignIn()}</span>

					<button
						type='button'
						className={s.closeBtn}
						onClick={closeModalHandler}
					>
						<CloseSvg />
					</button>
				</div>
				<div className={cn(s.redirectButtons)}>
					{redirectMethodTypes.map((method) => (
						<a
							key={method?.type}
							href={method.url}
							className={
								method.type === MethodTypesE.google
									? s.redirectButtons_googleButton
									: s.redirectButtons_telegramButton
							}
							onClick={() =>
								sendAnalyticsHandler(AnalyticsEventType.signin_click_social, {
									singnup_click_tab: method.type
								})
							}
						>
							{method.icon}
							<div className={s.redirectButtons_text}>{method.text}</div>
						</a>
					))}
				</div>

				<div className={s.dividerContainer}>
					<div className={s.dividerContainer_dividerLeft}></div>
					<div className={s.text}>{localizeText('divider_or')}</div>
					<div className={s.dividerContainer_dividerRight}></div>
				</div>

				<div className={cn(s.tabs, { [s.second]: methodType === MethodTypesE?.phone })}>
					{methodTypes.map((tab) => (
						<span
							key={tab?.type}
							className={cn(s.tab, {
								[s.tabActive]: tab?.type === methodType
							})}
							onClick={() => {
								setMethodType(tab.type);
								sendAnalyticsHandler(AnalyticsEventType?.signin_click_tab, {
									signin_tab: tab.type
								});
							}}
						>
							{tab.text}
						</span>
					))}
				</div>

				<div
					className={cn(s.inputBlock, { [s.hidden]: methodType !== MethodTypesE?.phone })}
				>
					<PhoneInput
						onNumberChange={(num) => formik.setFieldValue('phone', num)}
						setCountryCode={setCountryCode}
						onBlur={(isValid) => {
							formik?.setFieldTouched('phone', !isValid);
							// formik?.setFieldError(
							// 	'phone',
							// 	isValid ? '' : localizeText('enter_valide_phone')
							// );
						}}
						initValue={formik?.values?.phone}
						error={!!formik?.touched?.phone && Boolean(formik?.errors?.phone)}
					/>

					<FormError
						error={
							formik?.errors?.phone === 'bad_password_account'
								? ' '
								: formik?.errors?.phone && localizeText(formik?.errors?.phone)
						}
						isTouched={!!formik?.touched?.phone}
					/>
				</div>

				<div
					className={cn(s.inputBlock, {
						[s.hidden]: methodType !== MethodTypesE?.email
					})}
				>
					<Input
						name='email'
						type='text'
						value={formik?.values?.email.trim()}
						label={'E-mail'}
						placeholder='*****@gmail.com'
						onFocus={() => sendAnalyticsHandler(AnalyticsEventType?.singin_input_mail)}
						onChange={(e) => {
							formik.handleChange(e);
						}}
						error={!!formik?.touched?.email && Boolean(formik?.errors?.email)}
					/>
					<FormError
						error={
							formik?.errors?.email === 'bad_password_account'
								? ' '
								: formik?.errors?.email && localizeText(formik?.errors?.email)
						}
						isTouched={!!formik?.touched?.email}
					/>

					{formik?.values?.email?.includes('@') &&
						EMAIL_ENDS?.filter((e) => e?.includes(formik?.values?.email?.split('@')[1]))
							?.length > 0 &&
						!EMAIL_ENDS?.includes('@' + formik?.values?.email?.split('@')[1]) && (
							<div className={s.emailHelpDropDown}>
								{EMAIL_ENDS?.filter((e) =>
									e?.includes(formik?.values?.email?.split('@')[1])
								)?.map((e) => {
									return (
										<div
											onClick={() =>
												formik?.setFieldValue(
													'email',
													formik?.values?.email?.split('@')[0] + e
												)
											}
											className={s.emailHelpDropDown_item}
											key={e}
										>
											{formik?.values?.email?.split('@')[0] + e}
										</div>
									);
								})}
							</div>
						)}
				</div>

				<div className={s.inputBlock}>
					<Input
						name='password'
						type='password'
						placeholder={localizeText('enter_phone')}
						value={formik?.values?.password}
						label={localizeText('password')}
						onChange={formik.handleChange}
						onFocus={() =>
							sendAnalyticsHandler(AnalyticsEventType?.singin_input_password)
						}
						error={!!formik?.touched?.password && Boolean(formik?.errors?.password)}
					/>

					<FormError
						error={
							formik?.errors?.password === 'bad_password_account'
								? ' '
								: formik?.errors?.password && localizeText(formik?.errors?.password)
						}
						isTouched={!!formik?.touched?.password}
					/>
				</div>

				{forgotPassBanner && (
					<div className={s.fogotPassBanner}>
						<div className={s.fogotPassBanner_text}>
							<span>{localizeText('fogot_banner_title')}</span>
							<span>{localizeText('fogot_banner_descr')}</span>
						</div>

						<div className={s.fogotPassBanner_btns}>
							<button
								onClick={clickFogotPasswordBannerHandler}
								className={s.restore}
							>
								{localizeText('restore_password')}
							</button>
							<button
								onClick={closeFogotPasswordBannerHandler}
								className={s.close}
							>
								{localizeText('close_restore_password')}
							</button>
						</div>

						<img
							className={s.fogotPassBanner_img}
							alt='Girl'
							src={getImageByURL('../../../../../../assets/img/auth/fogot-password.webp')}
						/>
					</div>
				)}

				<div className={s.forgotPassBtn}>
					<button
						type='button'
						onClick={() => {
							setModalType(ModalType.resetPass);
							sendAnalyticsHandler(AnalyticsEventType?.singin_click_forgot_password);
						}}
						className={s.forgotPassBtn}
					>
						{localizeText('forgot_pass')}
					</button>
				</div>

				<div className={s.buttonWrapper}>
					<Button
						type={ButtonType.primary}
						btnType='submit'
						className={s.button}
						isDisabled={isAuthPending}
						text={
							isAuthPending ? (
								<Preloader
									styles={{ height: '100%', marginTop: 10, marginBottom: 10 }}
									isWhite={true}
								/>
							) : (
								renderLangSignIn()
							)
						}
						handle={() =>
							sendAnalyticsHandler(AnalyticsEventType?.singin_click_signin, {
								signin_switch: methodType
							})
						}
					/>
				</div>

				<div className={s.regBlock}>
					{localizeText('dont_have_account')}
					<button
						onClick={() => {
							sendAnalyticsHandler(AnalyticsEventType?.singin_click_singup);
							setModalType(ModalType.auth);
						}}
					>
						{renderLangSignUp()}
					</button>
				</div>
			</form>
		</Div100vh>
	);
};
