import React, { useEffect, useState } from 'react';
import { useLocales, useUrlLang } from '@lib/hooks';
import { setAuthType, setIsAuthPending } from '@store/reducers/auth/dispathcers';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import {
	AnalyticsEventType,
	AuthType,
	ButtonType,
	ModalType,
	RegisterPageTypesE,
	UrlQueries
} from '@types';
import {
	AcceptCheckbox,
	Button,
	CurrencySelect,
	FormError,
	Input,
	Preloader
} from '@components/common/elements';
import { CommentSvg } from '@components/svg';
import { useFormik } from 'formik';
import {
	getPhoneNumberByRegion,
	getStringQueryFromUrl,
	openCarrotChat,
	sendAnalyticsHandler
} from '@utils';
import { signUp } from '@lib/api/auth';
import { Link, useNavigate } from 'react-router-dom';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { authSelectors } from '@store/reducers/auth/selectors';
import { EMAIL_ENDS, SUPPORTED_LANGS } from '@config/global';
import { pathsConfig } from '@config/paths';
import { userSelectors } from '@store/reducers/user/selectors';
import isNull from 'lodash/isNull';
import Div100vh from 'react-div-100vh';
import s from './style.module.scss';
import cn from 'classnames';
import * as Yup from 'yup';
import 'yup-phone-lite';
import {
	BannersSignUp_1_2,
	BannersSignUp_3_4,
	HeaderSignUp,
	PhoneBlockSignUp,
	SocialLoginBtns
} from './parts';
import { CountryCode } from 'libphonenumber-js';

type RegisterValues = {
	email?: string;
	password?: string;
	phone?: string;
	currency?: string;
	timezone?: string;
	rememberMe?: boolean;
};

type PropsTypes = {
	type: RegisterPageTypesE;
};

const RegistrationPageSecond = (props: PropsTypes) => {
	const { type } = props;

	const { i18n } = useTranslation();
	const { langUrlPrefix } = useUrlLang();
	const { localizeText } = useLocales({
		path: 'components/common/modals',
		node: 'AuthModal'
	});
	const { isAuthed } = useSelector(authSelectors.authInfo);

	const tgIdQuery = getStringQueryFromUrl(UrlQueries.tgId);
	const refQuery = getStringQueryFromUrl(UrlQueries.ref);
	const partnerIdQuery = getStringQueryFromUrl(UrlQueries.partnerId);
	const sessionIdQuery = getStringQueryFromUrl(UrlQueries.session_id);

	const { mobileHeight, isMobile, isApp } = useSelector(commonUISelectors.commonUIInfo);
	const { isAuthPending } = useSelector(authSelectors.authInfo);
	const { locationData } = useSelector(userSelectors.userInfo);

	const [countryCode, setCountryCode] = useState<string | null>(null);

	const navigate = useNavigate();

	const signUp4ReturnUrl =
		type === RegisterPageTypesE?.sign_up_4 ? '/games/provider/spribe-aviator' : undefined;

	useEffect(() => {
		setAuthType(AuthType.signUp);
	}, []);

	const validationSchema = Yup.object().shape({
		email: Yup.string()
			.email('invalid_email')
			.transform((_, originalValue) =>
				typeof originalValue === 'string'
					? originalValue.replace(/\s/g, '')
					: originalValue
			)
			.required('need_fill_field'),

		phone:
			type === RegisterPageTypesE?.sign_up_2 && countryCode
				? Yup.string()
						.phone(countryCode as CountryCode, 'invalid_phone')
						.required('need_fill_field')
				: Yup.string(),

		password: Yup.string()
			.min(6, 'invalid_password_length')
			.max(40, 'invalid_password_length')
			.required('need_fill_field'),

		agreement: Yup.bool().oneOf([true], localizeText('need_approve'))
	});

	const phoneNumberRegion = countryCode && getPhoneNumberByRegion(countryCode);

	const handleSubmit = (values: RegisterValues) => {
		const countrycode = phoneNumberRegion ? `+${phoneNumberRegion?.nationalPrefix}` : '';

		const signUpValues = {
			email: values?.email ? values?.email?.replace(/\s/g, '') : undefined,
			password: values?.password,
			phone:
				type === RegisterPageTypesE?.sign_up_2 && values?.phone
					? `${countrycode}${values?.phone}`.replace(/[\s-]/g, '')
					: undefined,

			currency: values?.currency || locationData?.currency,
			timezone: locationData?.timezone || values?.timezone,
			rememberMe: values?.rememberMe
		};

		register(signUpValues);
	};

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
			phone: '',
			currency: '',
			timezone: '',
			agreement: true,
			rememberMe: true
		},
		validationSchema: validationSchema,
		onSubmit: handleSubmit
	});

	useEffect(() => {
		const creds = localStorage?.getItem('user_creds');

		if (creds) {
			const credsJSON = JSON.parse(creds);

			if (credsJSON?.email) {
				formik?.setFieldValue('email', credsJSON?.email);
			}

			if (credsJSON?.password) {
				formik?.setFieldValue('password', credsJSON?.password);
			}
		}
	}, []);

	useEffect(() => {
		if (isAuthed) {
			navigate(langUrlPrefix + pathsConfig?.home?.link);
		}
	}, []);

	const register = async (values: RegisterValues) => {
		const successFunc = (isFirstLogin: boolean) => {
			if (values?.rememberMe) {
				localStorage?.setItem(
					'user_creds',
					JSON.stringify({
						email: values?.email,
						password: values?.password,
						phone: values?.phone
					})
				);
			}

			setIsAuthPending(false);

			signUp4ReturnUrl
				? navigate(signUp4ReturnUrl)
				: navigate(
						isFirstLogin
							? langUrlPrefix + pathsConfig.payment.link
							: langUrlPrefix + pathsConfig.home.link
					);
		};

		await signUp(
			{
				...values,
				ref: !isNull(refQuery) ? refQuery : undefined,
				tgId: !isNull(tgIdQuery) ? +tgIdQuery : undefined,
				partnerId: !isNull(partnerIdQuery) ? partnerIdQuery : undefined,
				analyticalSpaceSessionId: !isNull(sessionIdQuery) ? sessionIdQuery : undefined,
				locale: SUPPORTED_LANGS?.includes(i18n?.language) ? i18n?.language : 'en',
				isApp: isApp
			},
			successFunc,
			formik.setFieldError
		);
	};

	const onAcceptCheckboxChange = (isAccepted: boolean) => {
		formik.setFieldValue('agreement', isAccepted);
	};

	const onCurrencyChange = (currency: string) => {
		formik.setFieldValue('currency', currency);
	};

	const heightStyle = {
		'--height': isMobile ? mobileHeight + 'px' : 'auto'
	} as React.CSSProperties;

	const selectBanners = () => {
		switch (type) {
			case RegisterPageTypesE.sign_up_1:
				return <BannersSignUp_1_2 />;
			case RegisterPageTypesE.sign_up_2:
				return <BannersSignUp_1_2 />;
			case RegisterPageTypesE.sign_up_3:
				return <BannersSignUp_3_4 />;
			case RegisterPageTypesE.sign_up_4:
				return <BannersSignUp_3_4 />;
			default:
				return null;
		}
	};

	return (
		<div className={s.wrapper}>
			<Div100vh className={s.inner}>
				<div className={s.contentBlock}>
					<HeaderSignUp />

					<div className={s.content}>{selectBanners()}</div>

					<div
						style={heightStyle}
						className={s.tabs}
					>
						<form
							onSubmit={formik.handleSubmit}
							className={s.form}
						>
							<SocialLoginBtns
								returnUrl={
									signUp4ReturnUrl
										? window?.location?.origin + signUp4ReturnUrl
										: undefined
								}
								currency={formik?.values?.currency}
							/>

							<div className={cn(s.emailBlock)}>
								<Input
									name='email'
									type='email'
									value={formik?.values?.email.trim()}
									label='E-mail'
									placeholder='*****@gmail.com'
									onChange={formik.handleChange}
									error={!!formik?.touched?.email && Boolean(formik?.errors?.email)}
									onFocus={() =>
										sendAnalyticsHandler(AnalyticsEventType?.registration_input_mail)
									}
									onBlur={() => formik?.setFieldTouched('email', true)}
								/>

								<FormError
									error={formik?.errors?.email && localizeText(formik?.errors?.email)}
									isTouched={!!formik?.touched?.email}
								/>

								{formik?.values?.email?.includes('@') &&
									EMAIL_ENDS?.filter((e) =>
										e?.includes(formik?.values?.email?.split('@')[1])
									)?.length > 0 &&
									!EMAIL_ENDS?.includes('@' + formik?.values?.email?.split('@')[1]) && (
										<div className={s.emailHelpDropDown}>
											{EMAIL_ENDS?.filter((e) =>
												e?.includes(formik?.values?.email?.split('@')[1])
											)?.map((e) => {
												return (
													<div
														onClick={() =>
															formik?.setFieldValue(
																'email',
																formik?.values?.email?.split('@')[0] + e
															)
														}
														className={s.emailHelpDropDown_item}
														key={e}
													>
														{formik?.values?.email?.split('@')[0] + e}
													</div>
												);
											})}
										</div>
									)}
							</div>

							{type === RegisterPageTypesE?.sign_up_2 && (
								<PhoneBlockSignUp
									phoneError={formik?.errors?.phone}
									phoneTouch={formik?.touched?.phone}
									phone={formik?.values?.phone}
									setFieldTouched={formik?.setFieldTouched}
									setFieldValue={formik?.setFieldValue}
									setExternalCountryCode={setCountryCode}
								/>
							)}

							<div className={cn(s.passInput)}>
								<Input
									name='password'
									type='password'
									placeholder={localizeText('enter_phone')}
									value={formik?.values?.password}
									label={localizeText('password')}
									onChange={formik.handleChange}
									error={!!formik?.touched?.password && Boolean(formik?.errors?.password)}
									onFocus={() =>
										sendAnalyticsHandler(AnalyticsEventType?.registration_input_password)
									}
								/>
								<FormError
									error={
										formik?.errors?.password && localizeText(formik?.errors?.password)
									}
									isTouched={!!formik?.touched?.password}
								/>
							</div>

							<CurrencySelect
								analyticsFunc={() =>
									sendAnalyticsHandler(AnalyticsEventType?.registration_click_currency)
								}
								analyticsFuncChoose={(val) =>
									sendAnalyticsHandler(AnalyticsEventType?.registration_choose_currency, {
										registration_currency: val
									})
								}
								onChange={onCurrencyChange}
							/>

							<Button
								type={ButtonType.primary}
								btnType='submit'
								rippleAnimation
								className={cn(s.button, s.buttonSignUp)}
								isDisabled={isAuthPending}
								handle={() =>
									sendAnalyticsHandler(AnalyticsEventType?.registration_click_sinup)
								}
							>
								{isAuthPending ? (
									<Preloader
										styles={{ height: '100%', marginTop: 10, marginBottom: 10 }}
										isWhite={true}
									/>
								) : (
									localizeText('register')
								)}
							</Button>

							<AcceptCheckbox
								initialValue={formik?.values?.agreement}
								onChange={onAcceptCheckboxChange}
							>
								<Trans
									ns='components/common/modals/translation'
									i18nKey={'AuthModal.i_accept_and_terms_of_service'}
									components={{
										1: (
											<Link
												className={s.termsandcondLink}
												onClick={() => {
													isMobile && setModalType(ModalType.politicsTermsAndConditions);
												}}
												to={
													isMobile
														? '#'
														: langUrlPrefix +
															pathsConfig.politics_terms_and_conditions?.link
												}
												target={isMobile ? '_self' : '_blank'}
											/>
										)
									}}
								/>
								<FormError
									error={formik?.errors?.agreement}
									isTouched={!!formik?.touched?.agreement}
								/>
							</AcceptCheckbox>

							<Button
								btnType='button'
								className={cn(s.button, s.buttonSignIn)}
								handle={() => {
									navigate(langUrlPrefix + pathsConfig?.home?.link);
									setModalType(ModalType.signIn);
									sendAnalyticsHandler(AnalyticsEventType?.registration_click_singin);
								}}
							>
								<span className={s.buttonSignInText}>
									{localizeText('do_you_have_account')}
								</span>
								<span className={s.buttonSignInTextBold}>{localizeText('sign_in')}</span>
							</Button>
						</form>

						<div className={s.support}>
							<span className={s.supportTitle}>{localizeText('support_24_7')}</span>
							<div className={s.supportButtons}>
								<Button
									rippleAnimation
									className={cn(s.button, s.supportButton)}
									handle={() => {
										openCarrotChat();

										sendAnalyticsHandler(AnalyticsEventType?.registration_click_support);
									}}
								>
									<CommentSvg />
									{localizeText('support')}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</Div100vh>
		</div>
	);
};

export default RegistrationPageSecond;
