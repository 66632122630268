import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

import { paymentWithdrawal } from '@lib/api/payment';
import { useUrlLang } from '@lib/hooks';
import {
	formatNumber,
	renderCurrencySymbol,
	sendAnalyticsHandler,
	formatBalance
} from '@utils';
import convertToUsers from '@utils/convertToUsers';
import { pathsConfig } from '@config/paths';

import { userSelectors } from '@store/reducers/user/selectors';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { bonusSelectors } from '@store/reducers/bonus/selectors';
import { setModalType } from '@store/reducers/common-ui/dispatchers';

import { AmountInput, BonusdDeletedModal } from '../../parts';
import { Button } from '@components/common/elements';
import { ErrorToast } from '@components/common/global-settings';

import s from './style.module.scss';

import { WithdrawalFiatTypeP } from '../../../../../../type';
import {
	AnalyticsEventType,
	ButtonType,
	ModalType,
	PaymentFlows,
	PaymentUIType
} from '@types';
import { InfoWarn, Timer } from '@components/svg';
import { ProgressBar } from '@components/common/elements/progress-bar';
import useTrimData from '@lib/hooks/useTrimData';

type WithdrawalDataType = {
	amount: number;
	wallet?: string;
	responseType?: PaymentUIType;
	fields?: Record<string, unknown>;
	currentPaymentError?: string;
	innerPlatformFlow?: null | PaymentFlows;
};

export const WithdrawalFiatType = (props: WithdrawalFiatTypeP) => {
	const {
		amount,
		localizeText,
		isNotSuitableAmount,
		currentPaymentMethod,
		innerPlatformFlow
	} = props;

	const navigate = useNavigate();
	const { langUrlPrefix } = useUrlLang();

	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);
	const { currency, balance, isWithdrawEnabled, isVerified, depositWager } = useSelector(
		userSelectors.userInfo
	);
	const { bonusBalances } = useSelector(bonusSelectors.bonusInfo);

	const [walletData, setWalletData] = useState({});

	const [isBonusesWarnModal, setIsBonusesWarnModal] = useState(false);

	const [isError, setIsError] = useState(false);
	const [errors, setErrors] = useState<Record<string, string | undefined>>({});

	const trimmedWalletData = useTrimData(walletData);

	useEffect(() => {
		setWalletData(trimmedWalletData);

		currentPaymentMethod?.settings?.fields?.map((f) => {
			setErrors((prevErrors: Record<string, string | undefined>) => ({
				...prevErrors,
				[f.field]: undefined
			}));
		});

		setIsError(false);
	}, [trimmedWalletData]);
	const openBonusWarnHandler = () => setIsBonusesWarnModal((d) => !d);

	const fields = currentPaymentMethod?.settings?.fields?.map((f) => f?.field);

	const currentPaymentError = currentPaymentMethod?.settings?.fields?.map(
		(f) => f.validator?.errorMessage
	);

	const handleButtonClick = () => setModalType(ModalType.withdrawalInfo);

	const withdrawalHandler = () => {
		if (isBonusesWarnModal) {
			openBonusWarnHandler();
		}

		if (!isWithdrawEnabled) {
			setModalType(ModalType.withdrawalBlocked);

			return;
		}

		if (!isVerified) {
			!isMobile
				? navigate(langUrlPrefix + pathsConfig.profile.link)
				: setModalType(ModalType.profileSettings);

			return;
		}

		const data: WithdrawalDataType = {
			amount: +amount * 100,
			responseType: currentPaymentMethod?.responseType,
			fields: {},
			currentPaymentError: currentPaymentError?.join('')
		};

		if (fields) {
			fields?.forEach((d) => {
				if (data.fields) {
					const value = walletData[d as keyof typeof walletData] as string;

					data.fields[d] = typeof value === 'string' ? value.trim() : value;
				}
			});
		}

		const notVerifiedFunc = () => {
			navigate(langUrlPrefix + pathsConfig.profile.link);
		};

		if (!isError) {
			paymentWithdrawal(data, isMobile, notVerifiedFunc);
		}

		return;
	};

	const withdrawalWithoutComission = () => {
		let sum = Number(amount);

		if (currentPaymentMethod?.settings?.withdrawFixedCommission) {
			sum = sum - Number(currentPaymentMethod?.settings?.withdrawFixedCommission / 100);
		}

		if (currentPaymentMethod?.settings?.withdrawPercentageCommission) {
			sum =
				sum -
				(Number(amount) * currentPaymentMethod?.settings?.withdrawPercentageCommission) /
					100;
		}

		return sum > 0 ? sum.toFixed(2) : 0;
	};

	const withdrawBtnHandler = () => {
		sendAnalyticsHandler(AnalyticsEventType?.payment_click_withdrawal);

		const fields = currentPaymentMethod?.settings?.fields || [];
		let isHasEmptyField = false;
		let isHasNotValidatedField = false;

		if (fields) {
			fields?.forEach((d) => {
				const reg = new RegExp(d?.validator?.regex || '', 'g');

				const fieldValue = walletData[d?.field as keyof typeof walletData];

				if (!reg.test(fieldValue)) {
					errors[d?.field] = d?.validator?.errorMessage || 'Invalid input';
					setIsError(true);
					isHasNotValidatedField = true;
				}

				if (!fieldValue) {
					isHasEmptyField = true;
				}
			});
		}

		if (isHasEmptyField) {
			toast((t) => ErrorToast({ t: t, text: 'withdrawal_all_fields_must_be_filled' }));

			return;
		}

		if (isHasNotValidatedField) {
			return;
		}

		bonusBalances && bonusBalances.length > 0
			? setIsBonusesWarnModal(true)
			: withdrawalHandler();
	};

	return (
		<>
			<div className={s.invoiceBlock}>
				{depositWager === 0 ? (
					<>
						<div className={s.balanceWithoutDepositWager}>
							<span>{localizeText('available_to_withdrawal')} </span>
							<span>
								{renderCurrencySymbol(currency)}
								{formatBalance(balance)}{' '}
							</span>
						</div>
					</>
				) : (
					<>
						<div className={s.balance}>
							{localizeText('available_to_withdrawal')}{' '}
							<span className={s.balance_text}>
								{renderCurrencySymbol(currency)}
								{balance - depositWager > 0
									? formatBalance(balance - depositWager)
									: 0}{' '}
							</span>
							<span>
								/ {renderCurrencySymbol(currency)}
								{formatBalance(balance)}
							</span>
						</div>
						<div className={s.progress}>
							<ProgressBar
								currentValue={balance - depositWager}
								maxValue={balance}
								customBg='#E7ECF51A'
								containerClassName={s.progress_container}
								fillerClassName={s.progress_filler}
								labelClassName={s.progress_label}
							/>
						</div>
						{currentPaymentMethod?.settings?.description && (
							<div
								onClick={handleButtonClick}
								className={s.comissionBlock}
							>
								<InfoWarn />
								<div className={s.comissionBlock_text}>{localizeText('more')}</div>
							</div>
						)}
					</>
				)}

				<div className={s.wrapperWithBg}>
					<AmountInput
						amount={amount}
						localizeText={localizeText}
						minWithdrawalAmount={currentPaymentMethod?.settings?.minInvoice}
						maxWithdrawalAmount={currentPaymentMethod?.settings?.maxInvoice}
						setWalletData={setWalletData}
						walletData={walletData}
						providerId={currentPaymentMethod?.id}
						setIsError={setIsError}
						errors={errors}
						setErrors={setErrors}
					/>

					{(!innerPlatformFlow || !isMobile) &&
						currentPaymentMethod?.settings?.minWithdraw &&
						currentPaymentMethod?.settings?.maxWithdraw && (
							<div className={s.minmax}>
								<span>{`${localizeText('min')}/${localizeText('max')}:`}</span>
								<span>
									{renderCurrencySymbol(currency?.toLowerCase())}
									{formatNumber(
										convertToUsers(Number(currentPaymentMethod?.settings?.minWithdraw))
									)}{' '}
									- {renderCurrencySymbol(currency?.toLowerCase())}
									{formatNumber(
										convertToUsers(Number(currentPaymentMethod?.settings?.maxWithdraw))
									)}
								</span>
							</div>
						)}
					<Button
						text={`${localizeText('withdraw')}${
							amount
								? ` ${renderCurrencySymbol(currency)}${formatNumber(Number(withdrawalWithoutComission()))}`
								: ''
						}`}
						isDisabled={
							isNotSuitableAmount ||
							Number(amount) > balance - depositWager ||
							Number(amount) <= 0
						}
						className={s.btn}
						type={ButtonType.primary}
						withoutGlare
						handle={withdrawBtnHandler}
					/>
					<div className={s.timerDescr}>
						<Timer />

						<span>
							{currentPaymentMethod?.settings?.descriptionUnderButton ||
								localizeText('withdraw_timer')}
						</span>
					</div>
				</div>
			</div>
			{isBonusesWarnModal && (
				<BonusdDeletedModal
					withdrawalHandler={withdrawalHandler}
					closeModalHandler={openBonusWarnHandler}
				/>
			)}
		</>
	);
};
